import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { User, LogOut } from 'lucide-react';

const ProfilePage = ({ setIsAuthenticated }) => {
  const { id } = useParams();
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post("/api/logout", {}, { withCredentials: true });
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      setError("Failed to log out. Please try again.");
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`/api/profile/${id}`, {
          withCredentials: true,
        });

        const currentUserResponse = await axios.get("/api/profile/me", {
          withCredentials: true,
        });

        if (parseInt(id) === currentUserResponse.data.uid) {
          navigate("/my-profile");
          return;
        }

        if (isMounted) {
          setProfile(response.data);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.response?.data?.detail || "An error occurred while fetching the profile.");
        }
      }
    };

    fetchProfile();

    return () => {
      isMounted = false;
    };
  }, [id, navigate]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <nav className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-semibold text-green-800">CourseLinx</h1>
              </div>
              <div className="flex items-center space-x-8">
                <button
                  onClick={() => navigate("/profile/me")}
                  className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
                >
                  <User className="h-4 w-4" />
                  <span>My Profile</span>
                </button>
                <button
                  onClick={handleLogout}
                  className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
                >
                  <LogOut className="h-4 w-4" />
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="bg-red-100 text-red-800 px-4 py-3 rounded mb-4">{error}</div>
        </div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="min-h-screen bg-gray-50">
        <nav className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-8">
            <div className="flex justify-between h-16">
              <div className="flex items-center">
                <h1 className="text-xl font-semibold text-green-800">CourseLinx</h1>
              </div>
              <div className="flex items-center space-x-8">
                <button
                  onClick={() => navigate("/profile/me")}
                  className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
                >
                  <User className="h-4 w-4" />
                  <span>My Profile</span>
                </button>
                <button
                  onClick={handleLogout}
                  className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
                >
                  <LogOut className="h-4 w-4" />
                  <span>Logout</span>
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-800"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <a href="/students" className="text-xl font-semibold text-green-800">
                CourseLinx
              </a>
            </div>

            <div className="flex items-center space-x-8">
              <button
                onClick={handleLogout}
                className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
              >
                <LogOut className="h-4 w-4" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-3xl mx-auto px-4 pt-16">
        {error && (
          <div className="bg-red-100 text-red-800 px-4 py-2 rounded mb-4">{error}</div>
        )}

        <div className="bg-white rounded-lg p-6 shadow-md">
          <div className="flex items-center space-x-4 mb-4">
            <div className="relative">
              <img
                src={profile.profile_picture || ""}
                alt={`${profile.name}'s profile`}
                className="h-24 w-24 rounded-full object-cover"
              />
            </div>
            <div>
              <h2 className="text-2xl font-semibold">{profile.name}</h2>
              <p className="text-gray-600">{profile.email}</p>
              <p className="text-sm text-gray-500">
                <strong>University:</strong> {profile.university}
              </p>
            </div>
          </div>

          <p className="mb-2">
            <strong>Bio:</strong> {profile.bio}
          </p>
          <p className="mb-4">
            <strong>Pronouns:</strong> {profile.pronouns}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {["Taken", "Current", "Planned"].map((status, idx) => {
              const classes =
                status === "Taken"
                  ? profile.classes.taken
                  : status === "Current"
                  ? profile.classes.current
                  : profile.classes.planned;

              return (
                <div key={idx} className="bg-gray-100 p-4 rounded-lg">
                  <h3 className="text-lg font-medium mb-2">{status} Classes</h3>
                  {classes.length > 0 ? (
                    classes.map((cls, index) => (
                      <p key={index}>
                        {cls.class_name} ({cls.term})
                      </p>
                    ))
                  ) : (
                    <p>No {status.toLowerCase()} classes listed.</p>
                  )}
                </div>
              );
            })}
          </div>

          <div className="mt-6 flex justify-end space-x-2">
            <button
              onClick={() => navigate("/students")}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium transition-colors flex items-center space-x-1"
            >
              <span>Back to Search</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;