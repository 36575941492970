import React, { useState, useEffect } from 'react';
import { Search, User, Settings, LogOut, Trash2 } from 'lucide-react';
import axios from 'axios';

axios.defaults.withCredentials = true;

export default function SearchStudents({ setIsAuthenticated }) {
  const [className, setClassName] = useState('');
  const [term, setTerm] = useState('');
  const [classType, setClassType] = useState('taken');
  const [nameSearchQuery, setNameSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchType, setSearchType] = useState(null); 
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [currentUniversity, setCurrentUniversity] = useState(() => {
    return localStorage.getItem('currentUniversity') || '';
  });

  useEffect(() => {
    // Only fetch if we don't have the data in localStorage
    if (!currentUser || !currentUniversity) {
      const fetchCurrentUser = async () => {
        try {
          const response = await axios.get('/api/profile/me');
          setCurrentUser(response.data);
          setCurrentUniversity(response.data.university);
          // Save to localStorage for future page loads
          localStorage.setItem('currentUser', JSON.stringify(response.data));
          localStorage.setItem('currentUniversity', response.data.university);
          setError('');
        } catch (error) {
          console.error('Failed to fetch current user data:', error);
          setError('Failed to fetch user data. Please try again later.');
        }
      };
      fetchCurrentUser();
    }
  }, []);

  const handleClassSearch = async (e) => {
    setSearchType('class');
    e.preventDefault();
    if (!className.trim()) {
      setSearchResults([]);
      setError('');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(
        `/api/students/${className}/${classType}`,
        { params: { term } }
      );
      setSearchResults(response.data);
      setError('');
    } catch (error) {
      setError(error.response?.data?.detail || 'An error occurred.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameSearch = async (e) => {
    setSearchType('name_bio');
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.get(
        '/api/search-users',
        { params: {  query: nameSearchQuery } }
      );
      setSearchResults(response.data);
      setError('');
    } catch (error) {
      setError(error.response?.data?.detail || 'An error occurred.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      // Clear localStorage
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUniversity');
      setIsAuthenticated(false);
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  useEffect(() => {
    if (!className.trim() && !term.trim()) {
      setSearchResults([]);
      setError('');
    }
  }, [className, term]);
  
  useEffect(() => {
    if (!nameSearchQuery.trim()) {
      setSearchResults([]);
      setError('');
    }
  }, [nameSearchQuery]);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-xl font-semibold text-green-800">CourseLinx</h1>
            </div>

            <div className="flex items-center space-x-8">
              <a href="/profile/me" className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2">
                <User className="h-4 w-4" />
                <span>Profile</span>
              </a>
              <button
                onClick={handleLogout}
                className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
              >
                <LogOut className="h-4 w-4" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-3xl mx-auto px-4 pt-3 pb-16">
        {/* Welcome Section */}
        <div className="bg-white rounded-lg p-6 mb-6">
          <h2 className="text-2xl font-medium text-gray-900 mb-2">
            Welcome back, {currentUser?.name || 'Student'}!
          </h2>
          <p className="text-gray-600">
            Your University: {currentUniversity}
          </p>
        </div>

        {error && (
          <div className="bg-red-100 text-red-800 px-4 py-2 rounded mb-4">{error}</div>
        )}

        {/* Search by Class Section */}
        <div className="bg-white rounded-lg p-6 mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Search Students by Course</h3>
          <form onSubmit={handleClassSearch} className="space-y-4">
            <div className="flex flex-col space-y-4">
              <div>
                <label className="block text-sm mb-1">Class Name</label>
                <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the subject as it appears on your transcript, and include the course number with no spaces. Ex: 'EECS 183' is 'EECS183'.</p>
                <input
                  type="text"
                  placeholder="Class Name"
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                  required
                  className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
                />
              </div>
              <div>
                <label className="block text-sm mb-1">Term (Optional)</label>
                <p className="text-xs text-gray-600 mb-1">Capitalize the first two letters of the term name, and join it with the last two numbers of the year without spaces. Ex: 'Fall 2023' is 'FA23'.</p>
                <input
                  type="text"
                  placeholder="Term"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
                />
              </div>
              <div className="flex space-x-4">
                <select
                  value={classType}
                  onChange={(e) => setClassType(e.target.value)}
                  required
                  className="px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm text-gray-600"
                >
                  <option value="taken">Taken</option>
                  <option value="current">Current</option>
                  <option value="planned">Planned</option>
                </select>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-8 py-2.5 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 text-sm font-medium transition-colors"
                >
                  {isLoading ? 'Searching...' : 'Search'}
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Search by Name Section */}
        <div className="bg-white rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Search Students by Name or Bio</h3>
          <form onSubmit={handleNameSearch} className="flex space-x-4">
            <input
              type="text"
              placeholder="Name"
              value={nameSearchQuery}
              onChange={(e) => setNameSearchQuery(e.target.value)}
              required
              className="flex-1 px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="px-8 py-2.5 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 text-sm font-medium transition-colors"
            >
              {isLoading ? 'Searching...' : 'Search'}
            </button>
          </form>
        </div>

        {/* Results Section */}
        {searchResults.length > 0 && (
        <div className="mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {searchResults.map((result, index) => (
              <div
                key={index}
                className="p-4 border border-gray-200 rounded-lg hover:border-green-200 transition-colors"
              >
                <div className="flex items-center space-x-3">
                  {result.profile_picture ? (
                    <img
                    src={result.profile_picture}
                    alt={result.name}
                    className="h-10 w-10 rounded-full object-cover"
                  />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-green-50 flex items-center justify-center">
                      <User className="h-5 w-5 text-green-600" />
                    </div>
                  )}
                  <div>
                    <h4 className="font-medium text-gray-900">{result.name}</h4>
                    <p className="text-sm text-gray-500">{result.email}</p>
                  </div>

                </div>
                {result.term && (
                  <p className="mt-2 text-sm text-gray-600">Term: {result.term}</p>
                )}
                {searchType === 'name_bio' && result.bio && (
                  <p className="mt-2 text-sm text-gray-600">Bio: {result.bio}</p>
                )}
                <a
                  href={`/profile/${result.uid}`}
                  className="mt-3 text-green-600 hover:text-green-700 text-sm font-medium inline-block"
                >
                  View Profile
                </a>
              </div>
      ))}
    </div>
  </div>
)}
      </main>
    </div>
  );
}