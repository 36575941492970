import React, { useState } from "react";
import { Mail } from "lucide-react";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    setError("");

    try {
    console.log("Sending email:", email);
      const response = await axios.post("/api/forgot-password", { email });
      setMessage(response.data);
    } catch (error) {
      setError(error.response?.data?.detail || "An error occurred. Please try again.");
      if (!error.response) {
        setError("Network error: Please check your connection and try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#2B5534] flex items-center justify-center">
      <div className="bg-white rounded-3xl w-full max-w-md mx-4 p-8">
        <div className="text-center mb-12">
          <h1 className="text-[#2B5534] text-2xl font-semibold mb-2">Forgot Password</h1>
          <p className="text-gray-600 text-sm">Enter your email to reset your password</p>
        </div>

        {message && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-6">
            <span className="block sm:inline">{message}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm mb-2">University Email</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                required
                className="w-full px-4 py-3 pl-10 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#4CAF50] text-white py-3 rounded-lg hover:bg-[#45a049] transition-colors"
          >
            {isLoading ? "Sending..." : "Send Reset Link"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;