import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";

const SignupPage = () => {
  const isMounted = useRef(true);
  const abortController = useRef(new AbortController());

  const [signupFields, setSignupFields] = useState({
    name: "",
    email: "",
    password: "",
    university: "",
    profile_picture: null,
    pronouns: "",
    bio: "",
    taken_classes: [{ class_name: "", term: "" }],
    planned_classes: [{ class_name: "", term: "" }],
    current_classes: [{ class_name: "", term: "" }],
  });
  const [universities, setUniversities] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  
  // State for university search input and filtered suggestions
  const [universitySearch, setUniversitySearch] = useState("");
  const [filteredUniversities, setFilteredUniversities] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const navigate = useNavigate();

  const resetState = () => {
    setUniversities([]);
    setError("");
    setIsLoading(false);
    setSignupSuccess(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      abortController.current.abort();
      resetState();
    };
  }, []);

  useEffect(() => {
    const fetchUniversities = async () => {
      abortController.current = new AbortController();

      try {
        if (!isMounted.current) return;
        const response = await axios.get("/api/schools", {
          headers: { "Content-Type": "application/json" },
          signal: abortController.current.signal,
        });

        if (!isMounted.current) return;
        setUniversities(response.data.schools || []);
        setError("");
      } catch (error) {
        if (!isMounted.current) return;
        if (axios.isCancel(error)) {
          console.log('Request cancelled');
          return;
        }
        console.error("Failed to load universities:", error);
        setError("Failed to load universities. Please try again later.");
      }
    };

    fetchUniversities();

    return () => {
      abortController.current.abort();
    };
  }, []);

  // Filter universities as the user types
  useEffect(() => {
    if (!isMounted.current) return;
    if (!universitySearch) {
      setFilteredUniversities([]);
      return;
    }
    const searchVal = universitySearch.toLowerCase();
    const results = universities
      .filter(u => u.toLowerCase().includes(searchVal))
      .slice(0, 10); // limit suggestions to top 10
    setFilteredUniversities(results);
  }, [universitySearch, universities]);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!isMounted.current) return;

    setIsLoading(true);
    const submitController = new AbortController();

    try {
      const formData = new FormData();

      Object.entries(signupFields).forEach(([key, value]) => {
        if (key === "profile_picture") {
          if (value) {
            formData.append(key, value);
          }
        } else if (["taken_classes", "current_classes", "planned_classes"].includes(key)) {
          const classes = value
            .filter((cls) => cls.class_name && cls.term)
            .map((cls) => [cls.class_name, cls.term]);
          if (classes.length > 0) {
            const classesJson = JSON.stringify(classes);
            formData.append(key, classesJson);
          }
        } else if (value != null) {
          formData.append(key, value.toString());
        }
      });

      const response = await axios.post("/api/signup", formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
        signal: submitController.signal,
      });

      if (!isMounted.current) return;
      setSignupSuccess(true);
      navigate("/verification-sent");
    } catch (error) {
      if (!isMounted.current || axios.isCancel(error)) return;

      console.error('Signup error:', {
        message: error.message,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data
      });

      if (!error.response) {
        setError("A network error occurred. Please check your connection and try again.");
      }
      
      const errorMessage = error.response?.data?.detail
        ? typeof error.response.data.detail === "string"
          ? error.response.data.detail
          : Array.isArray(error.response.data.detail)
          ? error.response.data.detail.map((err) => err.msg).join(", ")
          : JSON.stringify(error.response.data.detail)
        : "Signup failed. Please try again.";

        
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  };

  const updateField = (fieldName, value) => {
    if (!isMounted.current) return;
    setSignupFields((prev) => ({ ...prev, [fieldName]: value }));
  };

  const updateClassField = (classArrayName, index, field, value) => {
    if (!isMounted.current) return;
    setSignupFields((prev) => ({
      ...prev,
      [classArrayName]: prev[classArrayName].map((item, idx) =>
        idx === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const addClassRow = (classArrayName) => {
    if (!isMounted.current) return;
    setSignupFields((prev) => ({
      ...prev,
      [classArrayName]: [...prev[classArrayName], { class_name: "", term: "" }],
    }));
  };

  // When user picks a university from suggestions
  const handleUniversitySelect = (uni) => {
    updateField("university", uni);
    setUniversitySearch(uni);
    setFilteredUniversities([]);
  };

  return (
    <div className="min-h-screen bg-[#2B5534] flex items-center justify-center p-4">
      <div className="bg-white rounded-3xl w-full max-w-6xl p-8">
        <div className="text-center mb-8">
          <h2 className="text-[#2B5534] text-2xl font-semibold mb-2">Join CourseLinx</h2>
          <p className="text-gray-600 text-sm">Start your academic journey</p>
          <p className="text-gray-600 text-sm">Fields marked with <span className="text-red-600 font-semibold">*</span> are required</p>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {signupSuccess && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mb-4">
            <span className="block sm:inline">
              A verification email has been sent to your email address. Please check your inbox and follow the instructions to complete your registration.
            </span>
          </div>
        )}

        <form onSubmit={handleSignup} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm mb-1">First and Last Name <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.name}
                onChange={(e) => updateField("name", e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-1">University Email <span className="text-red-600">*</span></label>
              <input
                type="email"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.email}
                onChange={(e) => updateField("email", e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-1">Password <span className="text-red-600">*</span></label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  required
                  className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                  value={signupFields.password}
                  onChange={(e) => updateField("password", e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff className="h-4 w-4 text-gray-400" /> : <Eye className="h-4 w-4 text-gray-400" />}
                </button>
              </div>
            </div>

            <div className="relative">
              <label className="block text-sm mb-1">University <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                placeholder="Type to search..."
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={universitySearch}
                onChange={(e) => {
                  setUniversitySearch(e.target.value);
                  updateField("university", "");
                  setShowSuggestions(true);
                }}
              />
              {showSuggestions && filteredUniversities.length > 0 && (
              <ul className="absolute z-10 bg-white border border-gray-200 rounded-lg mt-1 w-full max-h-48 overflow-auto shadow-lg">
                {filteredUniversities.map((uni, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleUniversitySelect(uni);
                      setShowSuggestions(false);
                    }}
                    className="px-3 py-2 cursor-pointer hover:bg-green-100"
                  >
                    {uni}
                  </li>
                ))}
              </ul>
            )}
            </div>

            <div>
              <label className="block text-sm mb-1">Pronouns <span className="text-red-600">*</span></label>
              <input
                type="text"
                required
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.pronouns}
                onChange={(e) => updateField("pronouns", e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm mb-1">Profile Picture (Optional)</label>
              <input
                type="file"
                accept="image/*"
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                onChange={(e) => updateField("profile_picture", e.target.files[0])}
              />
            </div>
            <div className="sm:col-span-3">
              <label className="block text-sm mb-1">Bio <span className="text-red-600">*</span></label>
              <textarea
                required
                rows={3}
                className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB] border-none focus:ring-2 focus:ring-green-500 text-gray-900"
                value={signupFields.bio}
                onChange={(e) => updateField("bio", e.target.value)}
              ></textarea>
            </div>
          </div>

          {["taken_classes", "current_classes", "planned_classes"].map((classType, idx) => (
            <div key={idx} className="mt-4">
              <h3 className="text-lg font-medium mb-2">
                {classType === "taken_classes"
                  ? "Previous Classes (Optional)"
                  : classType === "current_classes"
                  ? "Current Classes (Optional)"
                  : "Planned Classes (Optional)"}
              </h3>
              <h3 className="text-sm text-gray-600 mb-2">
                {classType === "taken_classes"
                  ? "Please put previous classes where you would be comfortable answering some quick questions from another student."
                  : classType === "current_classes"
                  ? "Add the classes you are currently taking. This helps you connect with classmates."
                  : "Mention the classes you plan to take. This helps you find advice and guidance for future courses."}
              </h3>
              {signupFields[classType].map((cls, index) => (
  <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
    <div>
      <label className="block text-sm mb-1">Course Name</label>
      {index === 0 && (
        <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the subject as it appears on your transcript, and include the course number with no spaces. Ex: 'EECS 183' is 'EECS183'.</p>
      )}
      <input
        type="text"
        className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB]"
        value={cls.class_name}
        onChange={(e) => updateClassField(classType, index, "class_name", e.target.value)}
      />
    </div>
    <div>
      <label className="block text-sm mb-1">Term (required)</label>
      {index === 0 && (
        <p className="text-xs text-gray-600 mb-1">Capitalize the first two letters of the term name, and join it with the last two numbers of the year without spaces. Ex: 'Fall 2023' is 'FA23'.</p>
      )}
      <input
        type="text"
        className="w-full px-3 py-2 rounded-lg bg-[#F5F7FB]"
        value={cls.term}
        onChange={(e) => updateClassField(classType, index, "term", e.target.value)}
      />
    </div>
  </div>
))}
              <button
                type="button"
                onClick={() => addClassRow(classType)}
                className="px-3 py-1.5 bg-[#4CAF50] text-white rounded-lg hover:bg-[#45a049] transition-colors"
              >
                Add Class
              </button>
            </div>
          ))}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#4CAF50] text-white py-2 rounded-lg hover:bg-[#45a049] transition-colors mt-4"
          >
            {isLoading ? 'Signing up...' : 'Sign Up'}
          </button>
          
          <div className="text-center mt-4">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <Link to="/login" className="text-[#4CAF50] hover:underline">
                Login
              </Link>
            </p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-600">
              Want to go back?{" "}
              <Link to="/" className="text-[#4CAF50] hover:underline">
                Here
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;