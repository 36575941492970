import React, { useEffect, useState } from "react";
import axios from "axios";
import { Edit, Save, X, Trash2, PlusCircle, User, Settings, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const MyProfilePage = ({ setIsAuthenticated }) => {
  const [profile, setProfile] = useState(null);
  const [bio, setBio] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [takenClasses, setTakenClasses] = useState([]);
  const [currentClasses, setCurrentClasses] = useState([]);
  const [plannedClasses, setPlannedClasses] = useState([]);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetchProfile();
  }, []);

  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`/api/delete-user/${profile?.uid}`);
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Delete account error:", error);
      setError("Failed to delete account. Please try again.");
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get("/api/profile/me");
      const data = response.data;
      setProfile(data);
      setBio(data.bio || "");
      setPronouns(data.pronouns || "");
      setTakenClasses(data.classes?.taken || []);
      setCurrentClasses(data.classes?.current || []);
      setPlannedClasses(data.classes?.planned || []);

      if (data.profile_picture) {
        const mimeType = data.profile_picture_mime || "image/jpeg";
        setProfilePicturePreview(`data:${mimeType};base64,${data.profile_picture}`);
      } else {
        setProfilePicturePreview("");
      }

      setNewProfilePicture(null);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false);
        navigate("/login", { replace: true });
      } else {
        setError(error.response?.data?.detail || "Failed to fetch profile.");
        if (!error.response) {
          setError("Network error occurred while fetching profile. Please try refreshing the page.");
        }
      }
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post("/api/logout");
      setIsAuthenticated(false);
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      setError("Failed to log out. Please try again.");
      if (!error.response) {
        setError("A network error occurred. Unable to log out at this time.");
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      const filteredTaken = takenClasses.filter(
        (cls) => cls.class_name.trim() !== "" && cls.term.trim() !== ""
      );
      const filteredCurrent = currentClasses.filter(
        (cls) => cls.class_name.trim() !== "" && cls.term.trim() !== ""
      );
      const filteredPlanned = plannedClasses.filter(
        (cls) => cls.class_name.trim() !== "" && cls.term.trim() !== ""
      );
      
      const updatedProfileData = {
        bio,
        pronouns,
        taken_classes:
          filteredTaken.length > 0
            ? filteredTaken.map((cls) => [cls.class_name, cls.term])
            : [],
        current_classes:
          filteredCurrent.length > 0
            ? filteredCurrent.map((cls) => [cls.class_name, cls.term])
            : [],
        planned_classes:
          filteredPlanned.length > 0
            ? filteredPlanned.map((cls) => [cls.class_name, cls.term])
            : [],
      };

      if (newProfilePicture) {
        const formData = new FormData();
        formData.append("profile_picture", newProfilePicture);
        await axios.put("/api/profile/me/picture", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      await axios.put("/api/profile/me", updatedProfileData);
      setIsEditing(false);
      await fetchProfile();
    } catch (error) {
      setError(error.response?.data?.detail || "Failed to save changes.");
      if (!error.response) {
        setError("Failed to save changes due to a network error. Please try again.");
      }
    }
  };

  const addClassRow = (setClasses) => {
    setClasses((prevClasses) => [...prevClasses, { class_name: "", term: "" }]);
  };

  const handleClassChange = (setClasses, index, field, value) => {
    setClasses((prevClasses) => {
      const updatedClasses = [...prevClasses];
      updatedClasses[index][field] = value;
      return updatedClasses;
    });
  };

  const handleDeleteClass = (setClasses, index) => {
    setClasses((prevClasses) => {
      const updatedClasses = [...prevClasses];
      updatedClasses.splice(index, 1);
      return updatedClasses;
    });
  };
  const renderEditableClasses = (classes, setClasses) =>
    classes.map((cls, index) => (
      <div key={index} className="flex space-x-2 mb-2">
        <div className="flex-1">
          <label className="block text-sm mb-1">Course Name</label>
          {index === 0 && (  // Only show for first item
            <p className="text-xs text-gray-600 mb-1">Capitalize all letters of the subject as it appears on your transcript, and include the course number with no spaces. Ex: 'EECS 183' is 'EECS183'.</p>
          )}
          <input
            type="text"
            placeholder="Class Name"
            value={cls.class_name}
            onChange={(e) =>
              handleClassChange(setClasses, index, "class_name", e.target.value)
            }
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
          />
        </div>
        <div className="flex-1">
          <label className="block text-sm mb-1">Term (required)</label>
          {index === 0 && (  // Only show for first item
            <p className="text-xs text-gray-600 mb-1">Capitalize the first two letters of the term name, and join it with the last two numbers of the year without spaces. Ex: 'Fall 2023' is 'FA23'.</p>
          )}
          <input
            type="text"
            placeholder="Term"
            value={cls.term}
            onChange={(e) =>
              handleClassChange(setClasses, index, "term", e.target.value)
            }
            className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm"
          />
        </div>
        <div className="flex items-end mb-2">
          <button
            onClick={() => handleDeleteClass(setClasses, index)}
            className="p-1 text-red-600 hover:text-red-800 transition-colors"
            aria-label="Delete class"
          >
            <Trash2 className="h-3.5 w-3.5" />
          </button>
        </div>
      </div>
    ));

  const renderClasses = (classes, classType) => {
    if (classes.length > 0) {
      return classes.map((cls, index) => (
        <p key={index}>
          {cls.class_name} ({cls.term})
        </p>
      ));
    } else {
      return <p>No {classType.toLowerCase()} classes listed.</p>;
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePicture(file);
      setProfilePicturePreview(URL.createObjectURL(file));
    }
  };

  if (!profile) {
    return (
      <div className="text-center mt-8">
        <h2 className="text-xl font-medium">Loading...</h2>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
  <div className="max-w-7xl mx-auto px-8">
    <div className="flex justify-between h-16">
      <div className="flex items-center">
        <a href="/students" className="text-xl font-semibold text-green-800">
          CourseLinx
        </a>
      </div>

      <div className="flex items-center space-x-8">
        {showDeleteConfirm ? (
          <div className="flex items-center space-x-4">
            <span className="text-sm text-red-600">Are you sure?</span>
            <button
              onClick={handleDeleteAccount}
              className="text-red-600 hover:text-red-800 text-sm flex items-center space-x-2"
            >
              <Trash2 className="h-4 w-4" />
              <span>Yes, Delete</span>
            </button>
            <button
              onClick={() => setShowDeleteConfirm(false)}
              className="text-gray-600 hover:text-gray-800 text-sm"
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
  onClick={() => setShowDeleteModal(true)}
  className="text-red-600 hover:text-red-800 text-sm flex items-center space-x-2"
>
  <Trash2 className="h-4 w-4" />
  <span>Delete Account</span>
</button>
        )}
        <button
          onClick={handleLogout}
          className="text-gray-600 hover:text-gray-900 text-sm flex items-center space-x-2"
        >
          <LogOut className="h-4 w-4" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  </div>
</nav>

      {/* Main Content */}
      <main className="max-w-3xl mx-auto px-4 pt-16">
        {error && (
          <div className="bg-red-100 text-red-800 px-4 py-2 rounded mb-4">{error}</div>
        )}

        <div className="bg-white rounded-lg p-6 shadow-md">
          <div className="flex items-center space-x-4 mb-4">
            <div className="relative">
              <img
                src={profilePicturePreview || 
                  (profile?.profile_picture
                    ? `data:${profile.profile_picture_mime || "image/png"};base64,${profile.profile_picture}`
                    : "")
                }
                alt={`${profile?.name}'s profile`}
                className="h-24 w-24 rounded-full object-cover"
              />
              {isEditing && (
                <label htmlFor="profile-picture-input" className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs py-1 text-center cursor-pointer">
                  Change
                </label>
              )}
              <input
                id="profile-picture-input"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleProfilePictureChange}
              />
            </div>
            <div>
              <h2 className="text-2xl font-semibold">{profile?.name}</h2>
              <p className="text-gray-600">{profile?.email}</p>
              <p className="text-sm text-gray-500">
                <strong>University:</strong> {profile?.university}
              </p>
            </div>
          </div>

          {isEditing ? (
            <>
            <label className="block text-sm mb-1">Bio:</label>
              <textarea
                placeholder="Bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm mb-4"
                rows={3}
              ></textarea>
              <label className="block text-sm mb-1">Pronouns:</label>
              <input
                type="text"
                placeholder="Pronouns"
                value={pronouns}
                onChange={(e) => setPronouns(e.target.value)}
                className="w-full px-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-green-500 text-sm mb-4"
              />

<div className="bg-gray-100 p-4 rounded-lg mb-4">
  <p className="text-black-800">To add courses, press the Add Class button, edit the text boxes, and hit save changes at the bottom of the webpage when you're done.</p>
</div>


              {["Taken", "Current", "Planned"].map((status, idx) => {
                const classes =
                  status === "Taken"
                    ? takenClasses
                    : status === "Current"
                    ? currentClasses
                    : plannedClasses;

                const setClasses =
                  status === "Taken"
                    ? setTakenClasses
                    : status === "Current"
                    ? setCurrentClasses
                    : setPlannedClasses;

                return (
                  <div key={idx} className="bg-gray-100 p-4 rounded-lg mb-4">
                    <h3 className="text-lg font-medium mb-2">{status} Classes</h3>
                    {renderEditableClasses(classes, setClasses)}
                    <button
                      onClick={() => addClassRow(setClasses)}
                      className="px-4 py-2 mt-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-sm font-medium transition-colors flex items-center space-x-1"
                    >
                      <PlusCircle className="h-4 w-4" />
                      <span>Add {status} Class</span>
                    </button>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <p className="mb-2">
                <strong>Bio:</strong> {bio}
              </p>
              <p className="mb-4">
                <strong>Pronouns:</strong> {pronouns}
              </p>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {["Taken", "Current", "Planned"].map((status, idx) => {
                  const classes =
                    status === "Taken"
                      ? takenClasses
                      : status === "Current"
                      ? currentClasses
                      : plannedClasses;

                  return (
                    <div key={idx} className="bg-gray-100 p-4 rounded-lg">
                      <h3 className="text-lg font-medium mb-2">{status} Classes</h3>
                      {renderClasses(classes, status)}
                    </div>
                  );
                })}
              </div>
            </>
          )}

    <div className="mt-6 flex justify-end space-x-2">
            {isEditing ? (
              <>
                <button
                  onClick={handleSaveChanges}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-sm font-medium transition-colors flex items-center space-x-1"
                >
                  <Save className="h-4 w-4" />
                  <span>Save Changes</span>
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    fetchProfile(); // Reset changes
                  }}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium transition-colors flex items-center space-x-1"
                >
                  <X className="h-4 w-4" />
                  <span>Cancel</span>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setIsEditing(true)}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-sm font-medium transition-colors flex items-center space-x-1"
                >
                  <Edit className="h-4 w-4" />
                  <span>Edit Profile</span>
                </button>
                <button
                  onClick={() => navigate("/students")}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium transition-colors flex items-center space-x-1"
                >
                  <span>Back to Search</span>
                </button>
              </>
            )}
          </div>
        </div>
      </main>
      {/* Delete Account Modal */}
{showDeleteModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">Delete Account</h3>
      <p className="text-gray-600 mb-6">
        Are you sure you want to delete your account? This action cannot be undone and all your data will be permanently removed.
      </p>
      <div className="flex justify-end space-x-3">
        <button
          onClick={() => setShowDeleteModal(false)}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleDeleteAccount();
            setShowDeleteModal(false);
          }}
          className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-sm font-medium transition-colors flex items-center space-x-2"
        >
          <Trash2 className="h-4 w-4" />
          <span>Delete Account</span>
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default MyProfilePage;